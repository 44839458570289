import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";

export const frontmatter = {
  title: "Día 37",
  week: "Semana 6",
  month: "abr",
  day: "19",
  monthNumber: "04",
  date: "2020-04-19",
  path: "/cronologia/semana-06#dia-19-abr",
};
const Day37 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModText>
          El BOE publica hoy una{" "}
          <strong>orden del Ministerio de Sanidad</strong> por la que se
          establecen medidas excepcionales para garantizar que todos los
          ciudadanos tienen <strong>acceso a los productos higiénicos</strong>{" "}
          recomendados para la prevención de contagios por COVID-19.
        </ModText>
        <ModTwoCols
          src="/images/svg/26_mar_mascarilla.svg"
          alt="fijación del importe máximo mmascarillas y productos sanitarios"
          small={false}
        >
          En concreto, se establece el procedimiento para{" "}
          <strong>fijación del importe máximo</strong> de venta al público de
          los productos sanitarios (mascarillas quirúrgicas y guantes), así como
          de aquellos productos necesarios (mascarillas higiénicas y soluciones
          hidroalcóholicas).
        </ModTwoCols>
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-19"} />
        <ModLethalityEu fecha={"2020-04-19"} numPaises={8} />
        <ModDatafactCountry
          fecha={"2020-04-19"}
          country="us"
          data="casosConfirmados"
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 4.218 nuevos positivos y 410 personas fallecidas.
          Permanecen hospitalizadas 76.070 personas, 551 más que el día
          anterior, y 2.695 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.668, lo que supone un aumento de 115 personas.
        </ModText>
        <ModText>
          El 52,6% de los casos de COVID-19 son mujeres y la mediana de edad de
          los casos es 60 años, siendo mayor en hombres que en mujeres (62 vs 58
          años).
        </ModText>
        <ModGraph
          name="19-04-piramide-confirmados-covid-ES"
          alt="pirámide poblacional confirmados covid-19 España"
        />
        <ModText>
          Los hombres presentan una mayor prevalencia en la sintomatología de
          fiebre y disnea, mientras que el dolor de garganta y la clínica
          digestiva son significativamente más frecuentes en mujeres.
        </ModText>
        <ModGraph
          name="19-04-piramide-hospitalizados-covid-ES"
          alt="pirámide poblacional hospitalizados covid-19 España"
        />
        <ModText>
          Asimismo, los hombres presentan una mayor prevalencia de neumonía,
          enfermedades de base (cardiovascular, respiratoria, diabetes,
          hipertensión) y un mayor porcentaje de hospitalización, admisión en
          UCI, ventilación mecánica y letalidad que las mujeres.
        </ModText>
        <ModGraph
          name="19-04-piramide-uci-covid-ES"
          alt="pirámide poblacional uci covid-19 España"
        />
        <ModText>
          En definitiva, los hombres, los pacientes de mayor edad y con
          enfermedades de base y factores de riesgo, están más representados a
          medida que aumenta la gravedad.
        </ModText>
        <ModGraph
          name="19-04-piramide-fallecidos-covid-ES.svg"
          alt="pirámide poblacional defunciones covid-19 España"
        />
        <ModText>
          En España, la tasa de letalidad es del 10,4 % y la tasa de recuperados
          del 39,5 %.
        </ModText>
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.boe.es/boe/dias/2020/04/19/pdfs/BOE-A-2020-4525.pdf"
            name="Medidas excepcionales del Ministerio de Sanidad"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day37;
