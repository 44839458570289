import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day42 from "./dia-24-abr";
import Day41 from "./dia-23-abr";
import Day40 from "./dia-22-abr";
import Day39 from "./dia-21-abr";
import Day38 from "./dia-20-abr";
import Day37 from "./dia-19-abr";
import Day36 from "./dia-18-abr";

import SecWeekly, {
  SecWeeklyColumn,
} from "../../../components/Chronology/Modules/SecWeekly";
import ModMegaBanner from "../../../components/Chronology/Modules/ModMegaBanner";
import ModAlertBanner from "../../../components/Chronology/Modules/ModAlertBanner";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongGold } from "../../../components/Textstyles/Styles";
import ModImage from "../../../components/Chronology/Modules/ModImage";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <SecWeekly
        period="18 Abril - 24 de Abril"
        week="Semana 6 de confinamiento"
      >
        <SecWeeklyColumn>
          España certifica <strong>24 laboratorios</strong> de análisis de
          Covid-19 pudiendo hacer <strong>5000</strong> tests diarios
          <ModDottedLine />
          España alcanza los <strong>200.000</strong> casos confirmados
          <ModDottedLine />
          En España, el <strong>20%</strong> de los contagiados es personal
          sanitario
          <ModDottedLine />
          El Gobierno fija un <strong>importe máximo</strong> para el material
          sanitario
        </SecWeeklyColumn>
        <SecWeeklyColumn>
          <ModImage
            src="/images/svg/semana6-piramide-hospitalizados-covid-ES.svg"
            alt="piramide hospitalizados"
          />
        </SecWeeklyColumn>
      </SecWeekly>

      <Day42 />
      <ModMegaBanner>
        Autorizada una tercera prórroga del estado de alarma hasta el{" "}
        <StrongGold>10 de Mayo</StrongGold>.
      </ModMegaBanner>
      <Day41 {...props} />
      <Day40 {...props} />
      <Day39 {...props} />
      <ModMegaBanner>
        El gobierno ha solicitado la tercera prórroga del estado de alarma hasta
        el <StrongGold>09 de Mayo</StrongGold>.
      </ModMegaBanner>
      <ModAlertBanner color="brand04">
        España supera los 200.000 casos confirmados de contagios por coronavirus
        SARS-Cov-2
      </ModAlertBanner>
      <Day38 {...props} />
      <Day37 {...props} />
      <ModMegaBanner>
        Sanidad adapta el procedimineto de notificación de datos de COVID-19 por
        parte de <StrongGold>las comunidades autónomas</StrongGold>
      </ModMegaBanner>
      <Day36 {...props} />
    </LayoutWeek>
  );
};

export default Week;
