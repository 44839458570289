import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDatafactCCAA from "../../../components/Chronology/Modules/ModDatafactCCAA";

export const frontmatter = {
  title: "Día 41",
  week: "Semana 6",
  month: "abr",
  day: "23",
  monthNumber: "04",
  date: "2020-04-23",
  path: "/cronologia/semana-06#dia-23-abr",
};
const Day41 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDatafactCCAA
          fecha={"2020-04-23"}
          ccaa="es-vc"
          customText="Tasa de letalidad"
          customNumber="10,5%"
        />
        <ModCovidHighlight>
          La tasa de letalidad de Suecia supera el 10%.
        </ModCovidHighlight>
        <ModDataEu fecha={"2020-04-23"} />
        <ModLethalityEu fecha={"2020-04-23"} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 4.635 nuevos positivos y 440 personas fallecidas.
          Permanecen hospitalizadas 78.089 personas, 480 más que el día
          anterior, y 3.335 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.705, lo que supone un aumento de 5 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,4 % y la tasa de recuperados del 41,9 %.
        </ModText>
        <ModText>
          Hoy informamos en detalle sobre el progreso por provincias en uno{" "}
          <strong>Comunidad Valenciana</strong>. La evolución del estado de los
          infectados por covid-19 en cada una de las provincias queda recogido
          en los gráficos siguientes.
        </ModText>
        <ModGraph
          name="23-04-analisis-cv-valencia"
          alt="grafico analisis"
        />
        <ModGraph
          name="23-04-analisis-cv-alicante"
          alt="grafico analisis"
        />
        <ModGraph
          name="23-04-analisis-cv-castellon"
          alt="grafico analisis"
        />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day41;
